<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
			</a>
			<p class="nav__item">
				<span class="nav__text">
					픽
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub pick">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab tab--half">
						<a href="#">숙박</a>
						<a href="#" class="active">레저·티켓</a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="textbox textbox--margin">
						<h3 class="textbox__title">
							새로운 레저·티켓을 <br>
							픽 해보세요
						</h3>
						<p class="textbox__description">
							즐거움으로 가득찬 여행을 만날 수 있습니다.
						</p>
						<div class="textbox__button">
							<button class="btn btn-primary purple" type="button">새로운 레저·티켓 보러가기</button>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>